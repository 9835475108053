<template>
  <span v-if="this.isCznTerritorialRole()" class="two-columns">{{ $user.department.name }}</span>
  <span v-else-if="$user.role === 'ROLE_CZN_MANAGER' || $user.role === 'ROLE_REGIONAL_AGENCY' || $user.role === 'ROLE_REGIONAL_ADMINISTRATOR'"
        class="two-columns">
                        <span class="left-element">{{ $user.region.name }} ({{ regionNetType }})</span>
                        <v-autocomplete label="ЦЗН"
                                        :value="getDepartment()"
                                        @input="setDepartment($event);$forceUpdate()"
                                        :items="departments"
                                        item-text="name"
                                        return-object
                                        class="right-element"
                        ></v-autocomplete>
                    </span>
  <span v-else class="two-columns">
                      <v-autocomplete label="Регион"
                                      :value="getRegion()"
                                      @input="setRegion($event);$forceUpdate()"
                                      :items="$cznDepartmentsByRegionList"
                                      item-text="nameWithNetType"
                                      return-object
                                      class="left-element"
                                      @change="setDefaultDepartment()"
                      ></v-autocomplete>
                      <v-autocomplete label="ЦЗН"
                                      :value="getDepartment()"
                                      @input="setDepartment($event);$forceUpdate()"
                                      :items="departments"
                                      item-text="name"
                                      return-object
                                      class="right-element"
                      ></v-autocomplete>
                    </span>
</template>

<script>

export default {
  name: "RegionAndDepartmentPicker",
  props: ['value', 'nullableDepartment'], // {region: {...}, department: {...}}
  data: () => ({
    nullDepartmentObject: {name: 'В целом по субъекту РФ', nullPlaceholder: true},
    departments: null
  }),
  methods: {
    getRegion(){
      return this.value.region
    },

    getDepartment(){
      return this.value.department || this.nullDepartmentObject
    },

    setRegion(region){
      this.value.region = region;
    },

    setDepartment(department){
      if(department.nullPlaceholder){
        this.value.department = null
      } else {
        this.value.department = department
      }
    },
    setDefaultDepartment() {
      this.departments = JSON.parse(JSON.stringify(this.value.region.departments)).filter(e=>e.type==='territorial_czn' || e.type==='manager_czn');
      if(this.nullableDepartment) this.departments.unshift(this.nullDepartmentObject)

      if (this.$user.role === 'ROLE_CZN_MANAGER') {
        this.setDepartment(this.$user.department)
      } else {
        this.setDepartment(this.departments[0]);
      }
      this.$forceUpdate();
    },
    getRegionAndDepartment(departments, departmentId) {
      for (let dep of departments) {
        if (dep.id === departmentId) {
          return {
            department: dep,
            region: dep.region
          };
        }
      }
      return null;
    },

    isCznTerritorialRole(){
      return this.$user.role === 'ROLE_CZN_TERRITORIAL';
    },
    
    initialize(){
      if(!this.isCznTerritorialRole() && this.value?.region?.code && !(!this.value.department && !this.nullableDepartment)){ // если данные уже инициализированы родительским компонентом, пропускаем
        this.setRegion(this.$cznDepartmentsByRegionList.find(it => it.name === this.value.region.name));
        if (this.value.region.departments) {
          this.departments = JSON.parse(JSON.stringify(this.value.region.departments)).filter(e=>e.type==='territorial_czn' || e.type==='manager_czn');
          if(this.nullableDepartment && this.$user.role !== 'ROLE_CZN_MANAGER') this.departments.unshift(this.nullDepartmentObject)
        }
        return
      }
      if (this.$user.role === 'ROLE_CZN_TERRITORIAL' || this.$user.role === 'ROLE_CZN_MANAGER') {
        this.setRegion(this.$cznDepartmentsByRegionList.find(it => it.name === this.$user.region.name));
        if (this.value.region.departments) {
          this.departments = JSON.parse(JSON.stringify(this.value.region.departments)).filter(e=>e.type==='territorial_czn' || e.type==='manager_czn');
          this.departments.unshift(this.nullDepartmentObject);
        }
        this.setDepartment(this.$user.department);
      } else if (this.$user.role === 'ROLE_REGIONAL_AGENCY' || this.$user.role === 'ROLE_REGIONAL_ADMINISTRATOR') {
        this.setRegion(this.$cznDepartmentsByRegionList.find(it => it.name === this.$user.region.name));

        if (this.value.region.departments) {
          this.departments = JSON.parse(JSON.stringify(this.value.region.departments)).filter(e=>e.type==='territorial_czn' || e.type==='manager_czn');
          if(this.nullableDepartment) this.departments.unshift(this.nullDepartmentObject)
        }
        this.setDepartment(this.departments[0]);

      } else { //для админов и ВНИИ Труда
        this.setRegion(this.$cznDepartmentsByRegionList[0]);
        this.departments = JSON.parse(JSON.stringify(this.value.region.departments)).filter(e=>e.type==='territorial_czn' || e.type==='manager_czn');
        if(this.nullableDepartment) this.departments.unshift(this.nullDepartmentObject)
        this.setDepartment(this.departments[0]);
      }

      const departmentId = this.$route.query.departmentId
      if (departmentId) {
        const { region, department } = this.getRegionAndDepartment(this.$cznDepartmentsList, +departmentId)
        this.setRegion(this.$cznDepartmentsByRegionList.find(it => it.code === region.code))
        this.departments = JSON.parse(JSON.stringify(this.value.region.departments)).filter(e=>e.type==='territorial_czn' || e.type==='manager_czn');
        this.setDepartment(department)
      }
    }
  },
  watch: {
    nullableDepartment: {
      async handler() {
        this.initialize()
      }
    }
  },
  computed: {
    regionNetType(){
      switch (this.$user.region.netType){
        case 'CENTRALIZED': return 'централизованный'
        case 'DECENTRALIZED': return 'децентрализованный'
        default: return 'неизвестный тип'
      }
    }
  },
  beforeMount() {
    this.initialize()
  }
}
</script>

<style scoped>

</style>
